<template>
  <div class="flex justify-center items-center h-screen relative">
    <div>
      <a href="https://www.lipalink.com">
        <img
          class="h-12 object-contain mx-auto absolute top-10 left-10"
          src="@/assets/Lipalink-Logo.png"
        />
      </a>

      <div class="text-center px-10">
        <img class="h-28 mx-auto object-contain" src="@/assets/icons/404.png" />
        <p class="text-2xl font-extrabold pt-10">
          We are sorry, the page you requested cannot be found.
        </p>
        <p class="pt-5">
          The URL may be misspelled or the page you're looking for is no longer
          available. <br />Please use the url provided by lipalink.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",

};
</script>
